import _ from "lodash";
import { useSelector } from "react-redux";

import { FormFieldBackground } from "components/Forms/Form";
import InputBoolean from "components/Input/InputBoolean";
import InputMultipleCreate from "components/Input/InputMultipleCreate";
import { shouldMarkError } from "containers/lists/subcategories/lists/utils/shouldMark";
import { allWorkflowsSelector } from "containers/workflows/redux/selectors";
import useTranslations from "hooks/useTranslations";
import { IOption } from "model/application/components";
import { TViewMode } from "model/application/modal/CreateEditModal";
import { IClient } from "model/entities/Client";

import DeliveryWorkflowModal from "./components/DeliveryWorkflow/DeliveryWorkflowModal";
import {
  getErrorMessagesForDeliveryWorkflows,
  getWorkflowsStartingWithActivityStep,
} from "./components/DeliveryWorkflow/utils";

export interface IDeliveryManagement {
  viewMode?: TViewMode;
  client: IClient;
  onChange: (value: boolean, name: string) => void;
  onChangeDeliveryWorkflows: (values: IOption<string>[]) => void;
}
function DeliveryManagement({
  client,
  onChange,
  viewMode,
  onChangeDeliveryWorkflows,
}: IDeliveryManagement) {
  const lang = useTranslations();

  const clientLang = lang.containers.clients.subCategories.clients;
  const workflows = getWorkflowsStartingWithActivityStep(
    useSelector(allWorkflowsSelector)
  );

  function handleToggleDeliveryManagement(value: boolean) {
    onChange(value, "is_delivery_enabled");
  }

  const handleChangeDeliveryWorkflows = (values: IOption<string>[]) => {
    onChangeDeliveryWorkflows(values);
  };

  return (
    <>
      <FormFieldBackground viewMode={viewMode ?? "EDIT"}>
        <InputBoolean
          name={"is_delivery_enabled"}
          viewMode={viewMode}
          onChange={handleToggleDeliveryManagement}
          defaultValue={client.is_delivery_enabled}
          lang={{
            title: clientLang.createEditModal.deliveryManagement.title,
            tooltip: "",
          }}
          dataTestId="is_delivery_enabled"
        />
      </FormFieldBackground>

      {client.is_delivery_enabled && (
        <InputMultipleCreate
          name={"delivery_workflows"}
          onChange={handleChangeDeliveryWorkflows}
          langlabel={clientLang.createEditModal.inputDeliveryWorkflows}
          lang={lang}
          getErrorMessages={getErrorMessagesForDeliveryWorkflows}
          defaultValue={_.map(client.delivery_workflows, (e, index) => ({
            ...e,
            key: e.workflow_type,
            index: index,
          }))}
          defaultValueForNewElement={{
            workflow_type: "",
            workflow_id: "",
          }}
          additionnalProps={{ workflows }}
          chipTitleTemplate={`$workflow_type$`}
          CreateEditModal={DeliveryWorkflowModal}
          createModalTitleFunction={() =>
            clientLang.createEditModal.inputDeliveryWorkflows.createEditModal
              .createTitle
          }
          editModalTitleFunction={() =>
            clientLang.createEditModal.inputDeliveryWorkflows.createEditModal
              .editTitle
          }
          error={shouldMarkError("delivery_workflows")}
          direction="horizontal"
        />
      )}
    </>
  );
}

export default DeliveryManagement;
