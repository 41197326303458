import React from "react";

import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ReactSpeedometer from "react-d3-speedometer";

import { IKPI, KPI_TYPE } from "model/entities/Dashboard";

import Colors from "../Chart/Colors";
import PdfPrintableChartWrapper from "../Pdf/PdfPrintableChartWrapper";
import styles from "./styles";

const useStyles = makeStyles(styles as any);

export interface ICustomGaugeProps {
  gauge: IKPI;
  color: string;
}

const getSegmentColors = (
  gauge: IKPI,
  value: number,
  segments: any[],
  color: string
) => {
  const lightColor = "#ecebeb";
  if (value === 0) {
    return [Colors.interpolateHexColors("#fff", color, 0.25), lightColor];
  }
  if (!segments || segments.length === 1) {
    return [color];
  } else if (segments.length === 2) {
    return [color, lightColor];
  } else {
    if (gauge.target && value > gauge.target) {
      return [color, color, lightColor];
    } else {
      return [
        color,
        Colors.interpolateHexColors("#fff", color, 0.25),
        lightColor,
      ];
    }
  }
};

const getSegmentStops = (gauge: IKPI, value: number, maxValue: number) => {
  const target = gauge.target;
  const segments = [0, value, target, maxValue].filter(
    (curr) => curr !== null && curr !== undefined
  );
  return [...new Set(segments.map((val) => Number(val)).sort((a, b) => a - b))];
};

const getMaxValue = (gauge: IKPI, value: number) => {
  let maxValue = 100;
  maxValue = gauge && gauge.max_value ? (maxValue = gauge.max_value) : maxValue;
  return value > maxValue ? value : maxValue;
};

export const CustomGauge: React.FunctionComponent<ICustomGaugeProps> = ({
  color,
  gauge,
}) => {
  const classes = useStyles();
  // Get the first value of data
  const value = gauge.max_value
    ? Math.min(gauge.data, gauge.max_value)
    : gauge.data;
  const maxValue = getMaxValue(gauge, value);
  const isPercentage = gauge.is_percentage || !gauge.max_value;
  const valueWithUnit = value > -1 && isPercentage ? `${value}%` : `${value}`;
  const segments = getSegmentStops(gauge, value, maxValue);
  const segmentColors = getSegmentColors(gauge, value, segments, color);
  return (
    <Paper className={[classes.card, classes.rounded].join(" ")} elevation={0}>
      <div className={classes.chartCard}>
        <div className={classes.chartCardLeft}>
          <span className={classes.title}> {gauge.title} </span>
          <div className={classes.gaugeContainer}>
            <PdfPrintableChartWrapper
              title={gauge.title}
              type={KPI_TYPE.GAUGE}
              width={25}
              style={{
                height: "inherit",
                width: "inherit",
                display: "inline-block",
              }}
            >
              <ReactSpeedometer
                forceRender={true}
                paddingVertical={10}
                ringWidth={35}
                fluidWidth={true}
                maxSegmentLabels={1}
                needleHeightRatio={0}
                customSegmentStops={segments}
                segmentColors={segmentColors}
                textColor={color}
                currentValueText={valueWithUnit}
                minValue={0}
                maxValue={maxValue}
                value={value}
              />
            </PdfPrintableChartWrapper>
          </div>
        </div>

        <div className={classes.chartCardRight}>
          {gauge.description && (
            <Tooltip title={gauge.description} sx={{ marginTop: "-5px" }}>
              <IconButton>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </div>
    </Paper>
  );
};

export default CustomGauge;
