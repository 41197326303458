import {
  CUSTOM_FIELD_TYPE,
  FETCH_ITEMS_LIMIT,
  IList as IBEList,
  IListSchema as IBEListSchema,
  LIST_ACCESS_RIGHT_FOR_MOBILE_USER,
} from "fieldpro-tools/dist/src/types";
import _ from "lodash";

import { getListCategories } from "components/Input/InputMatrix/utils/getMatrixCategories";
import {
  getCustomFieldColumns,
  getMatrixFieldsSchema,
  getNameKey,
} from "components/Input/InputMatrix/utils/getQuestionColumns";
import { TABLE_COLUMN_TYPE } from "components/Table/model";
import prepareCustomFieldDataForBackend from "containers/workflows/subcategories/activities/utils/prepareCustomFieldDataForBackend";
import * as lang from "lang";
import {
  IList,
  IListSchema,
  LIST_ACCESS_RIGHT_FOR_WEB_USER,
  LIST_SCOPE,
} from "model/entities/List";
import { IListItem } from "model/entities/ListItem";
import { getMatrixRows } from "utils/formatting/matrixFields";
import { clone } from "utils/utils";

export const prepareListForBackend = (l: IList): IBEList => {
  const list: IList = clone(l);

  if (!list.scope) {
    list.scope = LIST_SCOPE.GLOBAL;
  }

  if (!list.mobile_access_right_per_role) {
    list.mobile_access_right_per_role = [];
  }

  if (
    list.mobile_access_right_per_role &&
    list.mobile_access_right_per_role.length != 0
  ) {
    list.mobile_access_right_per_role.forEach((mar: any) => {
      delete mar.index;
    });
  }

  if (!list.web_access_right) {
    list.web_access_right = LIST_ACCESS_RIGHT_FOR_WEB_USER.FULL_ACCESS;
  }

  if (!list.mobile_access_right) {
    list.mobile_access_right = LIST_ACCESS_RIGHT_FOR_MOBILE_USER.FULL_ACCESS;
  }

  if (
    !list.hasOwnProperty("can_update_items_ownership") ||
    list.can_update_items_ownership == null
  ) {
    list.can_update_items_ownership = false;
  }

  // replace "label" by "value" in options
  list.schema = prepareListSchemaForBackend(list.schema) as any;

  // remove all 'key' from the schema
  // remove all empty properties from the schema
  list.schema.forEach((attr) => {
    delete attr["key"];
  });

  list.schema = _.map(list.schema, (att) => {
    for (const prop in att) {
      if (
        att[prop] == null ||
        att[prop] === "" ||
        (Array.isArray(att[prop]) && !att[prop].length)
      ) {
        delete att[prop];
      }

      // NOTE: there is a bug, conditions are mutated somewhere before being sent to the BE
      // getExpressions returns a value without "nextOperator".
      if (att.conditions) {
        att.conditions = removeNextOperator(att.conditions);
      }
    }
    return att;
  });

  // remove the partial_data property and the other frontend properties
  delete (list as any).partial_data;
  delete (list as any).linked_workflows;
  delete (list as any).query;
  delete (list as any).deprecated_attributes;
  delete (list as any).options;
  return list as any as IBEList;
};

const removeNextOperator = <T extends {}>(expressions: T[]) => {
  return _.map(expressions, (expression) => _.omit(expression, "nextOperator"));
};

export const prepareListSchemaForFrontend = (
  schema: IBEListSchema[]
): IListSchema[] => {
  return schema.map((att: any) => {
    if (att.options) {
      att.options = att.options.map((opt: any, index: number) => {
        opt.label = opt.value;
        delete opt.value;
        opt.index = index;
        return opt;
      });
    }
    if (att.deprecatedOptions) {
      att.deprecatedOptions = att.deprecatedOptions.map(
        (opt: any, index: number) => {
          opt.label = opt.value;
          delete opt.value;
          opt.index = index;
          return opt;
        }
      );
    }
    return att;
  });
};

export const prepareOptionsForDeprecatedAttributes = (
  schema: IBEListSchema[]
) => {
  return schema.map((att: any) => {
    if (att.options) {
      att.deprecatedOptions = att.options.filter((opt: any) => opt.deprecated);
      att.options = att.options.filter((opt: any) => !opt.deprecated);
    }
    return att;
  });
};

export const prepareListSchemaForBackend = (
  schema: IListSchema[]
): IBEListSchema[] => {
  return schema.map((att: any) => {
    if (att.options) {
      att.options = att.options.map((opt: any) => {
        opt.value = opt.label;
        delete opt.index;
        delete opt.label;
        return opt;
      });
    }
    if (att.deprecatedOptions) delete att.deprecatedOptions;
    return att;
  });
};

export const prepareListsForFrontend = (lists: IBEList[]): IList[] => {
  let result: IBEList[] = clone(lists);
  result = result.map((l) => {
    // add active:true if no "active" attribute
    if (!l.hasOwnProperty("active")) {
      l.active = true;
    }
    // add patial_fetch and big list flag
    l["partial_data"] = true;
    if (l["items"]) {
      l["items"] = l["items"].filter((item: any) => item._active === true);
    }
    l["big_list"] = l["item_count"] >= FETCH_ITEMS_LIMIT;
    l["mobile_access_right_per_role"] = !(l as any).mobile_access_right_per_role
      ? []
      : (l as any).mobile_access_right_per_role.map(
          (mar: any, index: number) => ({
            ...mar,
            index: index,
          })
        );

    // temp fix to support old lists with EDIT_ONLY, EDIT_ONLY will be migrated in the next release.
    if (l.mobile_access_right === LIST_ACCESS_RIGHT_FOR_MOBILE_USER.EDIT_ONLY) {
      l.mobile_access_right = LIST_ACCESS_RIGHT_FOR_MOBILE_USER.READ_AND_EDIT;
    }
    return l;
  });
  // if there is no index, add an index to the attributes
  const compareFct = (a: any, b: any) => {
    if (!a) return -1;
    if (!b) return 1;
    if (a < b) return -1;
    return 1;
  };
  result = result.map((l) => {
    let newSchema = l.schema.filter((att) => att.deprecated);
    const indexes = l.schema.map((attr) => attr.index);
    newSchema = newSchema.concat(
      l.schema
        .filter((att) => !att.deprecated)
        .sort(compareFct)
        .map((att) => {
          if (att.options) {
            (att as any).deprecatedOptions = att.options.filter(
              (opt) => opt.deprecated
            );
            att.options = att.options.filter((opt) => !opt.deprecated);
          }
          if (att.index !== undefined) {
            return att;
          } else {
            let i = 0;
            while (indexes.includes(i)) {
              i++;
            }
            indexes.push(i);
            return { ...att, index: i };
          }
        })
        .sort((a, b) => (a.index || 0) - (b.index || 0))
    );
    l.schema = newSchema;
    return l;
  });
  // reformat options: change "value" to "label"
  result = result.map((l: any) => {
    l.schema = prepareListSchemaForFrontend(l.schema) as any;
    l.deprecated_attributes = prepareOptionsForDeprecatedAttributes(
      l.schema.filter((l: any) => l.deprecated)
    );
    return l;
  });
  return result as any[] as IList[];
};

// TODO: extract map
// TODO2: extract formatCustomFieldForBackend
// TODO3: check it works for MC, SC (and add SCOL, MCOL too ?)

export const prepareItemsForBackend = (
  items: IListItem[],
  listSchema: IListSchema[]
) => {
  if (!listSchema) {
    throw new Error("Missing listSchema in prepareItemsForBackend");
  }
  return _.map(items, (item) => {
    const formattedItem: any = {};
    if (item._id) formattedItem._id = item._id;
    if (item._owners) formattedItem._owners = item._owners;

    listSchema.forEach((attSchema) => {
      const attrKey = attSchema.column_tag;
      if (item.hasOwnProperty(attrKey)) {
        formattedItem[attrKey] = prepareCustomFieldDataForBackend({
          question: attSchema,
          answer: item[attrKey],
          listMultipleChoice: true,
        });
      }
    });

    return formattedItem;
  });
};

export const prepareItemsForFrontend = (
  items: IListItem[],
  listSchema: IListSchema[],
  allLists?: IList[]
): IListItem[] => {
  listSchema.forEach((att) => {
    switch (att.type) {
      case CUSTOM_FIELD_TYPE.MATRIX_ON_LIST:
        items = _.map(items, (item) => {
          if (item[att.column_tag]) {
            const list = _.find(allLists, { id: att.list_id, active: true });
            if (!list) {
              return null;
            }

            const categories = getListCategories(list, att);
            const matrixSchema = getMatrixFieldsSchema(att);
            const columns = [
              {
                name: "_name",
                label: "Item name",
                type: TABLE_COLUMN_TYPE.TEXT,
              },
              ...getCustomFieldColumns(matrixSchema),
            ];

            const rows = getMatrixRows({
              dynamicObjects: item[att.column_tag],
              fieldsSchema: matrixSchema,
              items: list.items,
              lists: allLists ?? [],
              lang: lang["en"],
            });

            return {
              ...item,
              [att.column_tag]: {
                title: item[getNameKey(att)] ?? item._displayed_name,
                categories,
                columns,
                rows,
              },
            };
          }

          return item as any;
        });
        break;
      default: /** do nothing */
    }
  });

  return items.map((i) => {
    // for the items with no "_active" property, add the property, set to true
    if (!i.hasOwnProperty("_active")) {
      i["_active"] = true;
    }
    return i;
  });
};

export const updateListSchemaByTag = (
  list: IList,
  newTag: string,
  oldTag: string,
  parent?: string
): IList => {
  const listCopy = { ...list };

  const updatedTypedAttributes = (schema?: IListSchema[]): IListSchema[] => {
    return _.map(schema ?? [], (sch) => ({
      ...sch,
      column_tag: sch.column_tag === oldTag ? newTag : sch.column_tag,
    }));
  };

  if (!parent) {
    return {
      ...list,
      schema: updatedTypedAttributes(listCopy.schema),
    };
  }

  const parentSchema = _.find(listCopy.schema, { column_tag: parent }) as
    | IListSchema
    | undefined;
  if (!parentSchema) return listCopy;
  const typed_questions = updatedTypedAttributes([
    ...(parentSchema.matrix?.typed_questions as IListSchema[]),
  ]);
  const newParentSchema = {
    ...parentSchema,
    matrix: {
      ...parentSchema.matrix,
      typed_questions,
    },
  };

  return {
    ...list,
    schema: _.map(listCopy.schema, (sch) => ({
      ...(sch.column_tag === parent ? newParentSchema : sch),
    })),
  } as IList<IListItem>;
};
