import { IWebhook, META_EXPRESSION_SCOPE, TRIGGER_EVENT } from "fieldpro-tools";
import _ from "lodash";

import { getPersonaOptions } from "containers/users/subcategories/utils";
import { IOption } from "model/application/components";
import { TInputAttributeLang } from "model/application/Lang";
import { TExpressionType } from "model/entities/Expression";
import { ITransformation } from "model/entities/Job";
import { IList } from "model/entities/List";
import { CUSTOM_FIELD_TYPE, IActivity } from "model/entities/Workflow";
import { getCustomFieldAttributesInSchema } from "utils/metaExpressions/getCustomFieldAttributesInSchema";
import { getCustomFieldOptions } from "utils/metaExpressions/getCustomFieldOptions";
import getFieldTagOptions from "utils/metaExpressions/getFieldTagOptions";
import getFinalResourceId from "utils/metaExpressions/getFinalResourceId";
import { getSchemaForMetaExpression } from "utils/metaExpressions/getSchemaForMetaExpression";
import { isFieldWithOptions } from "utils/metaExpressions/isFieldWithOptions";

import InputBoolean from "../InputBoolean";
import InputSelect from "../InputSelect";
import { CALLED_FROM } from "./MetaExpressionWizard";
import { getMobileUserFieldOptions } from "./utils/getMobileUserFieldOptions";

/**
 * Third step : field & custom fields selections
 */
export interface IMetaExpressionWizardThirdStepProps {
  // the properties to change
  calledFrom: CALLED_FROM | undefined;
  scope?: META_EXPRESSION_SCOPE;
  subscope?: META_EXPRESSION_SCOPE;
  custom_field1?: string;
  custom_field2?: string;
  custom_field3?: string;
  option_tag?: string;
  field?: string;
  // the additionnal props
  lists?: IList[]; // => all available lists
  activities?: IActivity[]; // => all available activities
  webhooks?: IWebhook[];
  transformations?: ITransformation[]; // => all available transformations
  currentRessourceId?: string; // => the current resource id selected (ex: activity id)
  numberIndex?: number;
  mobileUserRoleOptions: IOption[];
  // the usual properties generically passed in the step
  expressionType?: TExpressionType;
  calledFromMatrixTag?: string;
  shouldMarkError: any;
  labelToDisplay: any;
  useDefaultFields: boolean;
  useSecondDefaultFields?: boolean;
  isSecondLevel?: boolean;
  onChange: (value: any, name: string) => void;
  type?: CUSTOM_FIELD_TYPE;
  triggerEvent?: TRIGGER_EVENT;
}

export const MetaExpressionWizardThirdStep: React.FunctionComponent<
  IMetaExpressionWizardThirdStepProps
> = ({
  scope,
  subscope,
  custom_field1,
  custom_field2,
  custom_field3,
  option_tag,
  field,
  // additionnal props
  lists = [],
  activities = [],
  webhooks = [],
  transformations = [],
  currentRessourceId = "",
  numberIndex,
  mobileUserRoleOptions,
  //
  expressionType,
  calledFrom,
  calledFromMatrixTag,
  shouldMarkError,
  labelToDisplay,
  useDefaultFields,
  useSecondDefaultFields,
  isSecondLevel,
  onChange,
  type,
  triggerEvent,
}) => {
  const isFieldWithMatrix = (custom: string, schema: any[]) => {
    const customField = getCustomFieldAttributesInSchema(custom, schema);
    return (
      customField && customField?.type === CUSTOM_FIELD_TYPE.MATRIX_ON_LIST
    );
  };

  const isFieldWithSingleChoiceOnList = (custom: string, schema: any[]) => {
    const customField = getCustomFieldAttributesInSchema(custom, schema);
    return (
      customField &&
      customField?.type === CUSTOM_FIELD_TYPE.SINGLE_CHOICE_ON_LIST
    );
  };

  const onChangeAdditional = (value: any, name: string): void => {
    if (isSecondLevel) {
      if (name === "custom_field1") {
        onChange(value, "custom_field2");
        return;
      }
      if (name === "custom_field2") {
        onChange(value, "custom_field3");
        return;
      }
    }
    onChange(value, name);
  };

  const realCurrentRessourceId = getFinalResourceId(
    calledFrom,
    activities,
    scope,
    currentRessourceId,
    calledFromMatrixTag
  );

  let bannerMessage = "";
  let metaOrCustom = <></>;
  let mainSelect = <></>;
  let secondSelect = <></>;

  // build the banner message
  if (
    scope === META_EXPRESSION_SCOPE.ACTIVITY_REPORT ||
    scope === META_EXPRESSION_SCOPE.WORKFLOW_REPORT
  ) {
    bannerMessage = `*The current activity report is automatically selected`;
  } else if (
    scope === META_EXPRESSION_SCOPE.ITEM ||
    scope === META_EXPRESSION_SCOPE.ITEM_CELL
  ) {
    bannerMessage = `*The current list is automatically selected`;
  }
  const mainSchema = getSchemaForMetaExpression(
    activities,
    lists,
    transformations,
    webhooks,
    scope,
    realCurrentRessourceId,
    calledFromMatrixTag,
    numberIndex,
    type,
    true, //still include the matrix,when filtering the questions by type
    triggerEvent
  );

  const customFieldOptions: IOption[] = getCustomFieldOptions(
    mainSchema,
    scope,
    expressionType
  );

  let metaFieldOptions: IOption[] = getFieldTagOptions(
    scope,
    subscope,
    mobileUserRoleOptions
  );

  // Keep only the _customer_id option
  if (calledFrom === CALLED_FROM.CUSTOMER_TO_USE) {
    metaFieldOptions = _.filter(metaFieldOptions, { key: "_customer_id" });
  }

  if (calledFrom === CALLED_FROM.CUSTOMER_TO_USE) {
    useDefaultFields = true;
  } else if (customFieldOptions.length > 0 && metaFieldOptions.length > 0) {
    // if we have both custom field options and field options, we should add a switch
    const langTitle = {
      title: "Meta expression based on activity report normal field",
    } as TInputAttributeLang; // TODO: put this one on Lokalise

    metaOrCustom = (
      <InputBoolean
        name={isSecondLevel ? "useSecondDefaultFields" : "useDefaultFields"}
        lang={langTitle}
        defaultValue={useDefaultFields}
        onChange={onChange}
      />
    );
  } else if (metaFieldOptions.length == 0) {
    // we have to display the custom field only
    useDefaultFields = false;
  } else if (customFieldOptions.length == 0) {
    // we have to display the meta field only
    useDefaultFields = true;
  }

  if (useDefaultFields) {
    // display the meta fields
    mainSelect = (
      <InputSelect
        viewMode="CREATE"
        error={shouldMarkError("field")}
        name="field"
        lang={labelToDisplay.inputOption}
        options={_.sortBy(metaFieldOptions, "label")}
        value={field}
        onChange={onChange}
      />
    );

    if (field === "_role" && scope === META_EXPRESSION_SCOPE.USER) {
      secondSelect = (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("option_tag")}
          name="option_tag"
          lang={labelToDisplay.inputOption}
          options={mobileUserRoleOptions}
          value={option_tag}
          onChange={onChange}
        />
      );
    }
    if (field === "_persona" && scope === META_EXPRESSION_SCOPE.USER) {
      secondSelect = (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("option_tag")}
          name="option_tag"
          lang={labelToDisplay.inputOption}
          options={getPersonaOptions()}
          value={option_tag}
          onChange={onChange}
        />
      );
    }

    if (field === "_team_leader") {
      secondSelect = (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("option_tag")}
          name="option_tag"
          lang={labelToDisplay.inputOption}
          options={getMobileUserFieldOptions()}
          value={option_tag}
          onChange={onChange}
        />
      );
    }
  } else {
    // display the custom fields
    mainSelect = (
      <InputSelect
        viewMode="CREATE"
        error={shouldMarkError(
          isSecondLevel ? "custom_field2" : "custom_field1"
        )}
        name={isSecondLevel ? "custom_field2" : "custom_field1"}
        lang={labelToDisplay.inputField}
        options={customFieldOptions}
        value={custom_field1}
        onChange={onChange}
        //numberIndex={numberIndex}
        //getAllTagsSuperior={getAllTagsSuperior}
      />
    );

    if (
      custom_field1 &&
      isFieldWithOptions({
        field: custom_field1,
        schema: mainSchema,
        includeFieldsOnList: false,
      })
    ) {
      // NOTE: see FP-3746
      // Since SC and MC options represent strings, it would be better to create a MetaExpression on the SC or MC **field**
      // (not option) and then allow the user to select the option after the operator in conditions and operations.
      const fieldOptions = getCustomFieldAttributesInSchema(
        custom_field1,
        mainSchema
      )?.options;

      // first case : if we have options
      secondSelect = (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("option_tag")}
          name={"option_tag"}
          lang={labelToDisplay.inputOption}
          options={fieldOptions || []}
          value={option_tag}
          onChange={onChange}
          clearable
        />
      );

      // secondSelect = <></>;
    } else if (custom_field1 && isFieldWithMatrix(custom_field1, mainSchema)) {
      // second case: if we selected a matrix at first
      const matrixSchema = getSchemaForMetaExpression(
        activities,
        lists,
        transformations,
        webhooks,
        scope,
        realCurrentRessourceId,
        custom_field1 // the matrix tag
      );

      secondSelect = (
        <InputSelect
          viewMode="CREATE"
          error={shouldMarkError("custom_field2")}
          name={"custom_field2"}
          lang={labelToDisplay.inputField}
          options={getCustomFieldOptions(
            matrixSchema,
            META_EXPRESSION_SCOPE.ACTIVITY_REPORT,
            expressionType
          )}
          value={custom_field2}
          onChange={onChange}
          clearable
        />
      );
    } else if (
      custom_field1 &&
      isFieldWithSingleChoiceOnList(custom_field1, mainSchema)
    ) {
      // third case: if we selected a single choice on list first => we can call again the same component
      secondSelect = (
        <MetaExpressionWizardThirdStep
          scope={META_EXPRESSION_SCOPE.ITEM}
          calledFrom={CALLED_FROM.MATRIX_CELL}
          isSecondLevel={true}
          useDefaultFields={useSecondDefaultFields ? true : false}
          currentRessourceId={
            getCustomFieldAttributesInSchema(custom_field1, mainSchema)?.list_id
          }
          activities={activities}
          lists={lists}
          mobileUserRoleOptions={[]} // not used here
          expressionType={expressionType}
          custom_field1={custom_field2} // this one is important!!
          custom_field2={custom_field3}
          option_tag={option_tag}
          field={field}
          shouldMarkError={shouldMarkError}
          labelToDisplay={labelToDisplay}
          onChange={onChangeAdditional}
        />
      );
    }
  }

  return (
    <div>
      <>{bannerMessage}</>
      <>{metaOrCustom}</>
      <>{mainSelect}</>
      <>{secondSelect}</>
    </div>
  );
};
