import { useContext, useState } from "react";

import { Box } from "@material-ui/core";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { WarningModalContext } from "containers/app/AppProviders";
import { addItemsToListActionCreator } from "containers/lists/redux/actionCreators";
import { allListsSelector } from "containers/lists/redux/selectors";
import { IQuery } from "containers/lists/utils";
import { useTranslations } from "hooks";
import { formatString } from "lang/utils";
import { IOption } from "model/application/components";
import { IListItem } from "model/entities/ListItem";

import BulkImportMenu from "../Base/BulkImportMenu";
import EmptyOptionsView from "../Base/EmptyOptionsView";
import OptionsView from "../Base/OptionsView";
import { IInputBaseLayout } from "../InputBaseLayout/InputBaseLayout";
import { IInputMultipleSelectPropsBase } from "../InputMultipleSelect/InputMultipleSelect";
import InputMultipleSelectOnListBase from "./InputMultipleSelectOnListBase/InputMultipleSelectOnListBase";
import { IMultipleOptionsOnListSelectorProps } from "./InputMultipleSelectOnListBase/MultipleOptionsOnListSelector";
import MultipleOptionsOnSmallList from "./MultipleOptionsOnSmallList";

export const MAXIMUM_ENTRIES_FOR_SMALL_LIST = 6;

export interface IInputMultipleSelectOnListProps
  extends IInputBaseLayout,
    Omit<IInputMultipleSelectPropsBase, keyof IInputBaseLayout | "lang">,
    Pick<IMultipleOptionsOnListSelectorProps, "showFilter"> {
  listId: string;
  skipFetchInitialEntries?: boolean;
  defaultFilter?: IQuery;
}

function InputMultipleSelectOnList({
  viewMode,
  disabled,
  options,
  langlabel,
  error,
  viewStacked,
  labelIcons,
  defaultValue,
  highlightContent,
  noOptionsText,
  multipleSelection,
  name,
  onChange,
  limit,
  onBulkImport,
  bulkModalConfirmBtnText,
  bulkModalDialogTitle,
  BulkModal,
  onDownload,
  showDownloadButton,
  csvHeaderLine,
  listId,
  showFilter,
  forceDropDownMenu,
  defaultFilter,
}: Readonly<IInputMultipleSelectOnListProps>) {
  const lists = useSelector(allListsSelector);
  const list = lists.find((l) => l.id === listId);
  const listSize = list?.item_count;
  const { title, tooltip } = langlabel;
  const lang = useTranslations();
  const warningModal = useContext(WarningModalContext);
  const dispatch = useDispatch();

  const [tempSelectedOptions, setTempSelectedOptions] = useState<IOption[]>(
    defaultValue || []
  );
  const [selectedOptions, setSelectedOptions] = useState<IOption[]>(
    defaultValue || []
  );

  function shouldforceDropDownMenu() {
    if (forceDropDownMenu) return true;
    if (listSize && listSize <= MAXIMUM_ENTRIES_FOR_SMALL_LIST) return false;
    if (_.size(options) <= MAXIMUM_ENTRIES_FOR_SMALL_LIST) return false;

    return true;
  }

  function saveOptionsAsPartialItems(options: Array<IOption>) {
    //Add the option to the items store if missing
    //Some dialogs may rerender the component and the options are lost since they are not stored in the store
    const optionsAsPartialItems: Array<IListItem> = _.map(
      options,
      (opt): IListItem => {
        return {
          _id: opt.key,
          _active: true,
          _name: opt.label,
        };
      }
    );
    dispatch(
      addItemsToListActionCreator({
        items: optionsAsPartialItems,
        listId: listId,
        type: "_",
      })
    );
  }

  function handleAddOptions(options: Array<IOption>) {
    let newOptions: Array<IOption>;
    if (!multipleSelection) {
      newOptions = _.compact([_.first(options)]);
    } else {
      newOptions = [...options, ...tempSelectedOptions];
    }
    if (_.isNumber(limit) && _.size(newOptions) > limit) {
      const limitModal = lang.components.inputMultipleSelect.limitWarningModal;
      warningModal.openWarningModal({
        title: limitModal?.title ?? "Warning",
        description: formatString(limitModal?.description, [limit]),
      });
      return;
    }
    const optionsToSet = _.uniqBy(newOptions, "key");
    setTempSelectedOptions(optionsToSet);
    return optionsToSet;
  }

  function handleRemoveOptions(options: Array<IOption>) {
    const newSelectedOptions = _.filter(
      tempSelectedOptions,
      (selectedOption) => {
        return !_.find(options, (option) => option.key === selectedOption.key);
      }
    );
    setTempSelectedOptions(newSelectedOptions);
    return newSelectedOptions;
  }

  if (disabled || viewMode === "VIEW") {
    return (
      <OptionsView
        key={JSON.stringify(options)}
        label={title}
        tooltip={tooltip}
        viewMode={viewMode}
        error={error}
        viewStacked={viewStacked}
        labelIcons={labelIcons}
        disabled={disabled}
        options={defaultValue || []}
        highlightContent={highlightContent}
      />
    );
  }

  if (_.isEmpty(options)) {
    return (
      <EmptyOptionsView
        options={options}
        tooltip={tooltip}
        label={title}
        disabled={disabled}
        noOptionsText={noOptionsText}
      />
    );
  }

  return (
    <Box>
      {shouldforceDropDownMenu() ? (
        <Box>
          <InputMultipleSelectOnListBase
            listId={listId}
            multipleSelection={multipleSelection}
            options={options}
            langlabel={langlabel}
            selectedOptions={tempSelectedOptions}
            viewMode={viewMode}
            onAddOptions={handleAddOptions}
            onRemoveOptions={handleRemoveOptions}
            showFilter={showFilter}
            onSave={(options) => {
              const newOptions = options ?? tempSelectedOptions;
              setSelectedOptions(newOptions);
              saveOptionsAsPartialItems(newOptions);
              if (onChange) {
                onChange(newOptions, name);
              }
            }}
            onCancel={() => {
              setTempSelectedOptions(selectedOptions);
            }}
            onClose={() => {
              setTempSelectedOptions(selectedOptions);
            }}
            defaultValue={defaultValue}
            defaultFilter={defaultFilter}
          />
        </Box>
      ) : (
        <MultipleOptionsOnSmallList
          listId={listId}
          options={options}
          defaultValue={defaultValue}
          handleAddOptions={handleAddOptions}
          handleRemoveOptions={handleRemoveOptions}
          langlabel={langlabel}
          name={name}
          disabled={disabled}
          error={error}
          multipleSelection={multipleSelection}
          viewMode={viewMode}
          onChange={onChange}
        />
      )}

      <BulkImportMenu
        langlabel={langlabel}
        BulkModal={BulkModal}
        bulkModalConfirmBtnText={bulkModalConfirmBtnText}
        bulkModalDialogTitle={bulkModalDialogTitle}
        csvHeaderLine={csvHeaderLine}
        onBulkImport={onBulkImport}
        onDownload={onDownload}
        showDownloadButton={showDownloadButton}
      />
    </Box>
  );
}

export default InputMultipleSelectOnList;
