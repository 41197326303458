import React, { ComponentProps } from "react";

import { withStyles } from "@material-ui/core/styles";
import { ColorSchemeId } from "@nivo/colors";
import { ResponsiveLine, Serie } from "@nivo/line";
import {
  interpolateBlues,
  interpolateGreens,
  interpolateOranges,
  interpolatePurples,
  schemeAccent,
  schemeCategory10,
  schemeDark2,
  schemePastel1,
  schemePastel2,
  schemeSet1,
  schemeSet2,
} from "d3-scale-chromatic";
import { AXIS_TYPE, HEATMAP_COLOR_PALETTE } from "fieldpro-tools";
import _ from "lodash";

import * as colors from "assets/colors";
import { MULTIPLE_LINE_CHART_COLOR_PALETTE } from "containers/dashboards/components/modals/utils";
import { IKPI, INivoConfiguration, KPI_TYPE } from "model/entities/Dashboard";

import Axes from "./Axes";
import Chart from "./Chart";
import { ChartDataUtils, NUMBER_SEPARATOR } from "./ChartDataUtils";
import { SliceTooltip } from "./SliceTooltip";
import styles from "./styles";
import { processOptimetriksLineChartData } from "./utils/processOptimetriksLineChartData";

const CustomSymbol: React.FC<{
  size: number;
  borderColor: string;
}> = ({ size, borderColor }) => (
  <g>
    <circle
      style={{ boxShadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.8)" }}
      fill="#fff"
      r={size}
      strokeWidth={2}
      strokeOpacity="0.3"
      stroke={colors.lightgray}
    />
    <circle r={size / 2} strokeWidth={0} stroke={"0px"} fill={borderColor} />
  </g>
);

const OptimetriksLineChart: React.FC<{
  uid: string;
  chart: IKPI<Array<any>>;
  nivoConfiguration: INivoConfiguration;
}> = ({ uid, chart, nivoConfiguration }) => {
  const colors = ChartDataUtils.getChartColors(chart.data);
  const maxValue =
    chart?.data?.reduce((acc, curr) => {
      const tempMax = curr?.data?.reduce(
        (ac: any, cur: any) => (cur.y > ac ? cur.y : ac),
        -Infinity
      );
      return tempMax > acc ? tempMax : acc;
    }, -Infinity) * 1.2;

  const yScale = Axes.getScale(
    nivoConfiguration.axeYType as AXIS_TYPE,
    nivoConfiguration.minValue,
    maxValue
  );

  let axisBottom = Chart.defaultAxisBottom(nivoConfiguration.axeXType);
  axisBottom = nivoConfiguration.tickValues
    ? Object.assign(axisBottom, {
        tickValues: nivoConfiguration.tickValues,
      })
    : axisBottom;

  const enableYAxis = nivoConfiguration.enableYAxis;

  const getChartLegends = (): ComponentProps<
    typeof ResponsiveLine
  >["legends"] => {
    if (chart.type === KPI_TYPE.MULTIPLE_LINE_CHART && chart.grouped_mode) {
      return [
        {
          anchor: "top",
          direction: "row",
          itemHeight: 20,
          itemWidth: 100,
          translateY: -30,
          itemsSpacing: 50,
        },
      ];
    }
    if (chart.type === KPI_TYPE.MULTIPLE_LINE_CHART && !chart.grouped_mode) {
      return Chart.multiLegendSettings;
    }
    return [];
  };

  return (
    <span id={`lineChartContainer${uid}`}>
      <ResponsiveLine
        curve={nivoConfiguration.curve ? nivoConfiguration.curve : "linear"}
        colors={
          colors.length > 0 && chart.type === KPI_TYPE.MULTIPLE_LINE_CHART
            ? colors.map((item) => item.color)
            : chart.type !== KPI_TYPE.MULTIPLE_LINE_CHART
            ? nivoConfiguration.colors
            : getChartColorScheme(
                chart.heatmap_color || HEATMAP_COLOR_PALETTE.default
              )
        }
        data={processOptimetriksLineChartData(chart.data as Serie[])}
        margin={
          chart.type === "LINE_CHART"
            ? Chart.settings["LINE_CHART"].margin
            : _.merge(
                Chart.settings["MULTIPLE_LINE_CHART"].margin,
                chart.full_width
                  ? {
                      top: 30,
                      bottom: 40,
                      right: 35,
                    }
                  : {}
              )
        }
        axisTop={null}
        axisRight={null}
        yScale={{
          ...(yScale as any),
        }}
        axisBottom={{
          ...axisBottom,
          format: (value) => {
            return Axes.formatAxisValue(value, nivoConfiguration.axeXType);
          },
        }}
        axisLeft={enableYAxis ? Chart.defaultAxisLeft : null}
        pointLabel={(e) => {
          const yFormatted = ChartDataUtils.formatValue(e.y, NUMBER_SEPARATOR);
          return yFormatted;
        }}
        enablePointLabel={!enableYAxis}
        enablePoints={!chart.grouped_mode}
        enableCrosshair={false}
        enableGridX={false}
        enableGridY={false}
        pointSymbol={CustomSymbol}
        pointSize={10}
        lineWidth={4}
        legends={getChartLegends()}
        pointColor={{ from: "serieColor", modifiers: [] }}
        pointBorderWidth={1}
        pointBorderColor={{ from: "serieColor", modifiers: [] }}
        enableArea={nivoConfiguration.enableArea ? true : false}
        areaOpacity={nivoConfiguration.enableArea ? 1 : 0}
        debugSlices={false}
        enableSlices="x"
        useMesh={true}
        sliceTooltip={({ slice }) => {
          const points = slice.points.map((point) => ({
            id: point.serieId,
            indexValue: point.data.xFormatted,
            value: point.data.yFormatted,
            color: point.serieColor,
          }));
          return <SliceTooltip id={""} slice={points} />;
        }}
      />
    </span>
  );
};

export default withStyles(styles)(OptimetriksLineChart);

export function getChartColorScheme(color: HEATMAP_COLOR_PALETTE) {
  const defaultColors = [colors.teal, colors.BurntOrange, colors.blue];

  const zeroToOne = _.range(0.1, 1, 0.2);

  const MULTIPLE_LINE_CHART_COLOR_MAP: Record<
    MULTIPLE_LINE_CHART_COLOR_PALETTE,
    ColorSchemeId | readonly string[] | string[]
  > = {
    default: defaultColors,
    turbo: schemeSet1,
    spectral: schemePastel2,
    rainbow: schemePastel1,
    inferno: schemeCategory10,
    red_yellow_green: ["#FF4C4C", "#FFB22C", "#88D66C"],
    red_yellow_blue: ["#FF4C4C", "#FFB22C", "#378CE7"],
    cividis: schemeDark2,
    magma: schemeSet2,
    plasma: schemeAccent,
    viridis: schemeCategory10,
    blues: _.map(zeroToOne, (n) => interpolateBlues(n)),
    greens: _.map(zeroToOne, (n) => interpolateGreens(n)),
    oranges: _.map(zeroToOne, (n) => interpolateOranges(n)),
    purples: _.map(zeroToOne, (n) => interpolatePurples(n)),
  };
  return MULTIPLE_LINE_CHART_COLOR_MAP[color] ?? defaultColors;
}
