import { IImageDetectionData } from "fieldpro-tools";
import { Dispatch } from "redux";

import IPreparedPictureFilterParams from "containers/pictures/model/IPreparedPictureFilterParams";
import IPicture from "model/entities/Picture";
import {
  ajaxRequestAction,
  ajaxSuccessAction,
} from "redux/actionCreators/ajaxActionCreator";
import {
  extractDataAndCheckErrorStatus,
  treatErrorNotification,
} from "redux/actions/appActions";
import IRootState, { IDispatchAndGetState } from "redux/store/model";

import * as lang from "../../../lang";
import { getLang } from "../../authentication/redux/selector";
import { convertImageDetectionData } from "../components/modals/utils";
import { downloadPictures } from "../utils";
import {
  fetchPictureAnalysisApiCall,
  fetchPicturesByClientApiCall,
} from "./api";
export type IFetchPicturesResult = {
  pictures: IPicture[];
  totalPictures: number;
  prevQuery?: IPreparedPictureFilterParams;
};

export type IFetchPicturesForClientActionFunc = (
  filters: IPreparedPictureFilterParams,
  isDownload?: boolean,
  append?: boolean
) => IDispatchAndGetState<IFetchPicturesResult | undefined>;

export const fetchPicturesForClientAction: IFetchPicturesForClientActionFunc = (
  filters
) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());

    try {
      const data = await fetchPicturesByClientApiCall(filters);

      const { total_pictures, pictures } = data.data.data;

      dispatch(ajaxSuccessAction());

      return { pictures, totalPictures: total_pictures };
    } catch (error) {
      treatErrorNotification(
        dispatch,
        "fetchPicturesError",
        error,
        null,
        currLang
      );
    }
  };
};

export interface IFetchPictureDetectionsAction {
  id: string;
}
export const fetchPictureDetectionsAction = ({
  id,
}: IFetchPictureDetectionsAction) => {
  return async (dispatch: Dispatch, getState: () => IRootState) => {
    const currLang = lang[getLang(getState())];
    dispatch(ajaxRequestAction());
    try {
      const serverResponse = await fetchPictureAnalysisApiCall({
        picture_ids: [id],
      });
      const data = extractDataAndCheckErrorStatus<{
        picture_analysis: IImageDetectionData[];
      }>(serverResponse);

      const { picture_analysis } = data;
      dispatch(ajaxSuccessAction());

      const mappedImageDetectionData = picture_analysis.map((data) =>
        convertImageDetectionData({ data })
      );
      return mappedImageDetectionData;
    } catch (error) {
      treatErrorNotification(
        dispatch,
        "fetchPicturesDetectionsError",
        error,
        null,
        currLang
      );

      return [];
    }
  };
};

type IDownloadPicturesForClientActionFunc = (
  pictures: IPicture[]
) => IDispatchAndGetState<void>;

export const downloadPicturesForClientAction: IDownloadPicturesForClientActionFunc =
  (pictures) => {
    return async (dispatch, getState) => {
      const currLang = lang[getLang(getState())];
      dispatch(ajaxRequestAction());
      try {
        await downloadPictures(pictures);
        dispatch(ajaxSuccessAction());
      } catch (error) {
        treatErrorNotification(
          dispatch,
          "downloadPicturesError",
          error,
          null,
          currLang
        );
      }
    };
  };

export interface IPictureActions {
  fetchPicturesForClientAction: IFetchPicturesForClientActionFunc;
  downloadPicturesForClientAction: IDownloadPicturesForClientActionFunc;
}
